var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('back-off', {
    attrs: {
      "url": _vm.backUrl,
      "title": "Preview"
    }
  }), _c('div', {
    staticClass: "custom-context-box",
    staticStyle: {
      "width": "100%",
      "height": "100vw",
      "margin-left": "0vw",
      "margin-top": "25%",
      "position": "absolute",
      "overflow": "hidden",
      "border": "0vw"
    },
    attrs: {
      "id": "custom-context-box"
    }
  }, [_c('van-image', {
    staticClass: "custom-canvas-image-box",
    attrs: {
      "id": "custom-canvas-image-box",
      "width": "100%",
      "height": "100vw",
      "src": _vm.bgImages
    }
  }), _c('van-image', {
    staticClass: "custom-canvas-image-box",
    staticStyle: {
      "position": "absolute",
      "top": "0px",
      "left": "0px",
      "z-index": "99"
    },
    attrs: {
      "id": "custom-canvas-image-box",
      "width": "100%",
      "height": "100vw",
      "src": _vm.coverImg
    }
  }), _c('van-image', {
    staticClass: "custom-user-image-box",
    style: {
      width: _vm.userImageWidth,
      height: _vm.userImageHeight,
      top: _vm.userImageY,
      left: _vm.userImageX,
      position: 'absolute'
    },
    attrs: {
      "id": "custom-user-image-box",
      "src": _vm.userImageSrc
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };